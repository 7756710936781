import React, {Component, useState} from 'react'
import NavBlockLoggedIn from "./static/NavBlockLoggedIn";
import NavBlockNotLoggedIn from "./static/NavBlockNotLoggedIn";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {selectLoggedIn} from "./redux/loggedInSlice";

const Navbar = () => {
    const loggedInSelector = useSelector(selectLoggedIn);

    let navBlock;
    let iconLink;
    if (loggedInSelector.loggedIn) {
        navBlock = <NavBlockLoggedIn/>;
        iconLink = <Link className="navbar-brand d-flex align-items-center" to={'/welcome'}><span>Mamont LLC</span></Link>
    } else {
        navBlock = <NavBlockNotLoggedIn/>;
        iconLink = <Link className="navbar-brand d-flex align-items-center" to={'/'}><span>Mamont LLC</span></Link>
    }

    return (
        <nav className="navbar navbar-light navbar-expand-md sticky-top bg-secondary py-3">
            <div className="container">
                {iconLink}

                <button data-bs-toggle="collapse" className="navbar-toggler" data-bs-target="#nav-menu">
                    <span className="visually-hidden">Toggle navigation</span>
                    <span className="navbar-toggler-icon"></span>
                </button>

                {navBlock}
            </div>
        </nav>
    );
}

export default Navbar;