import './css/App.css'
//react
import React from 'react'
import ReactDOM from 'react-dom/client'
//react router
import {createBrowserRouter, RouterProvider,} from "react-router-dom";
//react redux
import {Provider} from "react-redux";
import store, {Persistor} from "./store";
import {PersistGate} from "redux-persist/integration/react";
//http client
import Axios from "axios";
//components
import Root from "./Root";
import SiteVisitsTable from "./SiteVisitsTable";
import Users from "./Users";
import Error404 from "./static/Error404";
import ErrorPage from "./static/ErrorPage";
import LoggedInPage from "./LoggedInPage";
import LoginPage from "./LoginPage";
import NotLoggedInPage from "./NotLoggedInPage";
import SiteVisit from "./site-visits/SiteVisit";
import AddUser from "./AddUser";
import CampaignsTable from "./campaigns/CampaignsTable";
import AddCampaign from "./campaigns/AddCampaign";

//Axios.defaults.baseURL = 'http://localhost:8080/api/ripper'
Axios.defaults.baseURL = 'https://galagames-app.net/api/ripper';

export const setAuthToken = (token) => {
    if (token) {
        Axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
    else delete Axios.defaults.headers.common["Authorization"];
}

const token = localStorage.getItem("token");
if (token) {
    setAuthToken(token);
}

export const wsEndpoint = 'https://galagames-app.net/api/ripper/ws';

const router = createBrowserRouter([
    {
        path: "/",
        element: <Root/>,
        errorElement: <ErrorPage/>,
        children: [
            {index: true, element: <NotLoggedInPage/>},
            {path: "welcome", element: <LoggedInPage/>},
            {path: "login", element: <LoginPage/>},
            {path: "site-visits/", element: <SiteVisitsTable/>},
            {path: "site-visits/:id", element: <SiteVisit/>},
            {path: "users", element: <Users/>},
            {path: "users/add", element: <AddUser/>},
            {path: "campaigns", element: <CampaignsTable/>},
            {path: "campaigns/add", element: <AddCampaign/>},
            {path: "*", element: <Error404/>}
        ]
    },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={Persistor}>
                <RouterProvider router={router}/>
            </PersistGate>
        </Provider>
    </React.StrictMode>
);