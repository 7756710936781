import React, {useEffect, useState} from 'react'
import Axios from "axios";
import {Link, useNavigate} from "react-router-dom";
import {setAuthToken} from "./index";
import Pagination from "react-js-pagination";

const TableHeader = () => {
    return (
        <thead>
        <tr className="table-info">
            <th>ID</th>
            <th>Username</th>
            <th>Role</th>
            <th>Control</th>
        </tr>
        </thead>
    )
}

const TableBody = ({users, deleteUser}) => {
    let rows = [];
    if (users !== undefined) {
        rows = users.map((user, index) => {
            return (
                <tr key={index}>
                    <td>{user.id}</td>
                    <td>{user.username}</td>
                    <td>{user.role}</td>
                    <td style={{paddingTop: 10 + 'px', paddingBottom: 10 + 'px'}}>
                        <div className="btn-group btn-group-sm" role="group">
                            <button className="btn btn-danger d-xxl-flex" onClick={() => deleteUser(user.id)}>
                                <span role="img" aria-label="control">🗑️</span>
                            </button>
                        </div>
                    </td>
                </tr>
            )
        })
    }

    return <tbody>{rows}</tbody>;
}

const UsersTable = () => {
    const [data, setData] = useState({},);
    const [pageSize, setSize] = useState(20);
    const [totalPages, setTotalPages] = useState(0);
    const [totalItemsCount, setTotalItemsCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate()

    const fetchUsers = (page) => {
        Axios.get('/users', {
            params: {
                page: page,
                size: pageSize
            }
        })
            .then(response => {
                setTotalPages(response.data.totalPages);
                setTotalItemsCount(response.data.totalElements)
                setData(response.data);
            })
            .catch(error => {
                console.log(error);
                if (error.response.status === 403) {
                    setAuthToken(null)
                    localStorage.clear()
                    navigate("/login")
                }
            });
    }

    const deleteUser = (id) => {
        Axios.delete('/users/' + id)
            .then(res => {
                window.alert("User successfully deleted")
            })
            .catch(err => {
                if (err.response.status === 403) {
                    setAuthToken(null)
                    localStorage.clear()
                    navigate("/login")
                } else {
                    window.alert("User was not deleted")
                }
            })
    }

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        fetchUsers(pageNumber - 1);
    }

    useEffect(() => {
        fetchUsers(0)
    }, []);

    return (
        <div className="container mt-4">
            <div className="table-responsive">
                <table className="table table-striped table-bordered">
                    <caption className="lead fw-semibold text-bg-info caption-top text-center">
                        Users
                        <Link to={"/users/add"} className="btn btn-sm btn-outline-success float-end mx-4" type="button">Add
                            User</Link>
                    </caption>
                    <TableHeader/>
                    <TableBody users={data.content} deleteUser={deleteUser}/>
                </table>
            </div>
            {
                data.totalElements > 20 ?
                    <Pagination hideNavigation
                                activePage={currentPage}
                                totalPages={totalPages}
                                itemsCountPerPage={pageSize}
                                totalItemsCount={totalItemsCount}
                                pageRangeDisplayed={5}
                                itemClass='page-item'
                                linkClass='btn btn-light'
                                onChange={handlePageChange}/>
                    :
                    null
            }
        </div>
    );
}

export default UsersTable;