export default function validateLogin(values) {
    const errors = {};
    if (!values.username) {
        errors.username = 'Required';
    } else if (values.username.length > 32 || values.username.length < 4) {
        errors.username = 'Must be 4-32 characters';
    }

    if (!values.password) {
        errors.password = 'Required';
    } else if (values.password.length > 32 || values.password.length < 8) {
        errors.password = 'Must be 8-32 characters';
    }

    return errors;
};

export function validateSave(values) {
    const errors = validateLogin(values);

    if(!values.role) {
        errors.role = 'Required'
    } else if (values.role !== 'USER' && values.role !== 'ADMIN') {
        errors.role = 'Incorrect role'
    }

    return errors;
};