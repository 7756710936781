import {Link, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {setAuthToken, wsEndpoint} from "../index";
import {logOut, selectLoggedIn} from "../redux/loggedInSlice";
import {useDispatch, useSelector} from "react-redux";
import Alert from 'react-bootstrap/Alert';
import {addAlert, removeAlert, removeOldestAlert, selectAlerts} from "../redux/alertSlice";

let stompClient = null;

function AlertDismissible({siteVisit, removeAlert}) {
    const [show, setShow] = useState(true);

    const dismiss = () => {
        setShow(false)
        removeAlert(siteVisit.id)
    }

    if (show) {
        return (
            <Alert variant="danger" onClose={() => dismiss()} dismissible>
                <Alert.Heading>New Site Visit</Alert.Heading>
                <p>
                    Check it out <Link to={"/site-visits/" + siteVisit.id}>here</Link>
                </p>
                <p>Email: {siteVisit.email}</p>
                <p>IP: {siteVisit.geoInfo.ipAddress}</p>
                <p>Location: {siteVisit.geoInfo.country}</p>
            </Alert>
        );
    }
    return null;
}

const NavBlockLoggedIn = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loggedInSelector = useSelector(selectLoggedIn);
    const alertSelector = useSelector(selectAlerts);

    useEffect(() => {
        connect();
    }, []);

    const connect = () => {
        if (stompClient !== null) {
            return;
        }
        const Stomp = require("stompjs");
        let SockJS = require("sockjs-client");
        SockJS = new SockJS(wsEndpoint);
        stompClient = Stomp.over(SockJS);
        stompClient.connect({}, onConnected, onError);
    };

    const onConnected = () => {
        console.log("WS connected");
        stompClient.subscribe(
            "/user/admin/queue/site-visit-notifications",
            onMessageReceived
        );
    };

    const onError = (err) => {
        console.log(err);
    };

    const onMessageReceived = (msg) => {
        let src = 'https://assets.mixkit.co/active_storage/sfx/933/933.wav';
        let audio = new Audio(src);
        audio.play();
        dispatch(addAlert(JSON.parse(msg.body)))
        if(alertSelector.alerts.length > 6) {
            dispatch(removeOldestAlert())
        }
    };

    const handleLogout = (event) => {
        event.preventDefault();
        localStorage.removeItem('token')
        setAuthToken(null)
        dispatch(logOut());
        navigate("/");
    }

    const dismissAlert = (id) => {
        dispatch(removeAlert(id))
    }

    const playSound = () => {
        let src = 'https://assets.mixkit.co/active_storage/sfx/2841/2841.wav';
        let audio = new Audio(src);
        audio.play();
    }

    return (
        <React.Fragment>
            <div className="collapse navbar-collapse" id="nav-menu">
                <ul className="navbar-nav mx-auto">
                    <li className="nav-item"><Link className="nav-link" to={'site-visits'}>Site visits</Link></li>
                    {loggedInSelector.isAdmin &&
                        <li className="nav-item"><Link className="nav-link" to={'users'}>Users</Link></li>}
                    <li className="nav-item"><Link className="nav-link" to={'campaigns'}>Campaigns</Link></li>
                    <li className="nav-item" onClick={playSound}><a className="nav-link">Play Sound!</a></li>
                </ul>
                <Link to={'/login'} className="btn btn-primary" onClick={handleLogout}>Log out</Link>
            </div>
            <div className="d-flex flex-column bd-highlight m-3 position-absolute top-0 start-0">
                {alertSelector.alerts ? alertSelector.alerts.map(alert => alert ? (<AlertDismissible key={alert.id} siteVisit={alert} removeAlert={dismissAlert}/>) : null) : null}
            </div>
        </React.Fragment>
    );
}

export default NavBlockLoggedIn;