import {combineReducers, configureStore} from '@reduxjs/toolkit'
import {persistStore, persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import loggedInReducer from './redux/loggedInSlice'
import alertReducer from './redux/alertSlice'

const rootReducer = combineReducers({
    loggedIn: loggedInReducer,
    alerts: alertReducer
})

const persistConfig = {
    key: 'root',
    storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
    reducer: {
        rootReducer: persistedReducer
    }
})

const Persistor = persistStore(store)

export {Persistor};
export default store;