import React, {useEffect, useState} from 'react'
import Axios from "axios";
import {Link, useNavigate} from "react-router-dom";
import {setAuthToken} from "../index";
import Pagination from "react-js-pagination";

const TableHeader = () => {
    return (
        <thead>
        <tr className="table-info">
            <th>ID</th>
            <th>Name</th>
            <th>Fish URL</th>
            <th>Original URL</th>
            <th>Control</th>
        </tr>
        </thead>
    )
}

const TableBody = ({campaigns, deleteCampaign}) => {
    let rows = [];
    if (campaigns !== undefined) {
        rows = campaigns.map((campaign, index) => {
            return (
                <tr key={index}>
                    <td>{campaign.id}</td>
                    <td>{campaign.name}</td>
                    <td>{campaign.fishUrl}</td>
                    <td>{campaign.originalUrl}</td>
                    <td style={{paddingTop: 10 + 'px', paddingBottom: 10 + 'px'}}>
                        <div className="btn-group btn-group-sm" role="group">
                            <button className="btn btn-danger d-xxl-flex" onClick={() => deleteCampaign(campaign.id)}>
                                <span role="img" aria-label="control">🗑️</span>
                            </button>
                        </div>
                    </td>
                </tr>
            )
        })
    }

    return <tbody>{rows}</tbody>;
}

const CampaignsTable = () => {
    const [data, setData] = useState({},);
    const [pageSize, setSize] = useState(20);
    const [totalPages, setTotalPages] = useState(0);
    const [totalItemsCount, setTotalItemsCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate()

    const fetchCampaigns = (page) => {
        Axios.get('/campaigns', {
            params: {
                page: page,
                size: pageSize
            }
        })
            .then(response => {
                setTotalPages(response.data.totalPages);
                setTotalItemsCount(response.data.totalElements)
                setData(response.data);
            })
            .catch(error => {
                console.log(error);
                if (error.response.status === 403) {
                    setAuthToken(null)
                    localStorage.clear()
                    navigate("/login")
                }
            });
    }

    const deleteCampaign = (id) => {
        Axios.delete('/campaigns/' + id)
            .then(res => {
                window.alert("Campaign successfully deleted")
            })
            .catch(err => {
                if (err.response.status === 403) {
                    setAuthToken(null)
                    localStorage.clear()
                    navigate("/login")
                } else {
                    window.alert("Campaign was not deleted")
                }
            })
    }

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        fetchCampaigns(pageNumber - 1);
    }

    useEffect(() => {
        fetchCampaigns(0)
    }, []);

    return (
        <div className="container mt-4">
            <div className="table-responsive">
                <table className="table table-striped table-bordered">
                    <caption className="lead fw-semibold text-bg-info caption-top text-center">
                        Campaigns
                        <Link to={"/campaigns/add"} className="btn btn-sm btn-outline-success float-end mx-4" type="button">Add Campaign</Link>
                    </caption>
                    <TableHeader/>
                    <TableBody campaigns={data.content} deleteCampaign={deleteCampaign}/>
                </table>
            </div>
            {
                data.totalElements > 20 ?
                    <Pagination hideNavigation
                                activePage={currentPage}
                                totalPages={totalPages}
                                itemsCountPerPage={pageSize}
                                totalItemsCount={totalItemsCount}
                                pageRangeDisplayed={5}
                                itemClass='page-item'
                                linkClass='btn btn-light'
                                onChange={handlePageChange}/>
                    :
                    null
            }
        </div>
    );
}

export default CampaignsTable;