import React from 'react'
import Navbar from "./Navbar";
import Footer from "./static/Footer";
import {Outlet} from "react-router-dom";

export default function Root() {
    return (
        <React.Fragment>
            <Navbar/>
            <Outlet/>
            <Footer/>
        </React.Fragment>
    );
}