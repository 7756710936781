import React, { Component } from 'react'

export default function Footer() {

    return (
        <footer className="footer mt-auto text-center bg-dark">
            <div className="container text-white py-4 py-lg-5">
                <p className="text-muted mb-0">2023 IC&nbsp;©</p>
            </div>
        </footer>
    );
}