export default function validate(values) {
    const errors = {};
    if (!values.name) {
        errors.name = 'Required';
    } else if (values.name.length > 64 || values.name.length < 4) {
        errors.name = 'Must be 4-64 characters';
    }

    if (!values.fishUrl) {
        errors.fishUrl = 'Required';
    } else {
        try {
            let url = new URL(values.fishUrl);
        } catch (_) {
            errors.fishUrl = 'Not a URL'
        }
    }

    if(!values.originalUrl) {
        errors.originalUrl = 'Required';
    } else {
        try {
            let url = new URL(values.originalUrl);
        } catch (_) {
            errors.originalUrl = 'Not a URL'
        }
    }

    return errors;
};