import {createSlice} from '@reduxjs/toolkit'

export const loggedInSlice = createSlice({
    name: 'loggedInState',
    initialState: {
        loggedIn: false,
        isAdmin: false,
        adminId: null
    },
    reducers: {
        logIn: (state, action) => {
            return {
                ...state,
                loggedIn: true,
                isAdmin: action.payload.isAdmin,
                adminId: action.payload.adminId
            }
        },
        logOut: (state) => {
            return {
                ...state,
                loggedIn: false,
                isAdmin: false,
                adminId: null
            }
        },
    },
})

export const {logIn, logOut} = loggedInSlice.actions

export const selectLoggedIn = (state) => state.rootReducer.loggedIn

export default loggedInSlice.reducer