import React from 'react'
import jwt_decode from "jwt-decode";
import {useNavigate} from "react-router-dom";

export default function LoggedInPage() {
    const navigate = useNavigate();
    let decodedToken = {
        sub: null
    };
    try {
        decodedToken = jwt_decode(localStorage.getItem("token"));
    } catch (e) {
        console.log("No auth")
        navigate("/");
    }

    return (
        <section className="py-4 py-xl-5">
            <div className="container h-100">
                <div className="row h-100">
                    <div
                        className="col-md-10 col-xl-8 text-center d-flex d-sm-flex d-md-flex justify-content-center align-items-center mx-auto justify-content-md-start align-items-md-center justify-content-xl-center">
                        <div>
                            <h2 className="text-uppercase fw-bold mb-3"><br/>Welcome, {decodedToken.sub}<br/><br/></h2>
                            <p className="mb-4">Choose one of the menu options on the navbar above</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}