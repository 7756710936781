import React, {useState} from "react";
import Axios from "axios";
import {setAuthToken} from "./index";
import {useNavigate} from "react-router-dom";
import {useFormik} from "formik";
import {validateSave} from "./validation/validateUser";

const roles = ["USER", "ADMIN"];
const AddUser = () => {
    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
            role: roles[0],
        },
        validate: validateSave,
        onSubmit: values => {
            Axios.post('/users', {
                id: null,
                username: values.username,
                password: values.password,
                role: values.role,
                enabled: true
            }, {
                headers: { 'Content-Type': 'application/json' }
            })
                .then(response => {
                    console.log(response)
                    navigate("/users")
                })
                .catch(error => {
                    console.log(error);
                    if (error.response.status === 403) {
                        setAuthToken(null)
                        localStorage.clear()
                        navigate("/login")
                    }
                })
        }
    })

    const navigate = useNavigate()

    return (
        <div className="container pt-5">
            <div className="row d-flex justify-content-center">
                <div className="col-auto col-sm-12 col-md-12 col-lg-12 col-xl-10 col-xxl-10">
                    <div className="card mb-5">
                        <div className="card-body p-sm-5">
                            <h2 className="text-center mb-4">Add User</h2>
                            <form className="text-center" onSubmit={formik.handleSubmit}>
                                <div className="input-group">
                                    <span className="text-white bg-primary input-group-text">Username</span>
                                    <input
                                        id="username"
                                        name="username"
                                        className="form-control"
                                        type="text"
                                        placeholder="Username"
                                        onChange={formik.handleChange}
                                        value={formik.values.username}
                                    />
                                    {formik.errors.username ?
                                        <div className="invalid-feedback d-block">{formik.errors.username}</div> : null}
                                </div>
                                <hr/>
                                <div className="input-group">
                                    <span className="text-white bg-primary input-group-text">Password</span>
                                    <input
                                        className="form-control"
                                        type="password"
                                        name="password"
                                        id="password"
                                        value={formik.values.password}
                                        onChange={formik.handleChange}
                                        placeholder="Password"
                                    />
                                    {formik.errors.password ? <div className="invalid-feedback d-block">{formik.errors.password}</div> : null}

                                </div>
                                <hr/>
                                <div className="input-group">
                                    <span className="text-white bg-primary input-group-text">Role</span>
                                    <select
                                        id="role"
                                        name="role"
                                        className="form-select"
                                        onChange={formik.handleChange}
                                        value={formik.values.role}
                                    >
                                        {roles.map((role, index) => {
                                            return <option key={index} value={role}>{role}</option>
                                        })}
                                    </select>
                                    {formik.errors.role ? <div className="invalid-feedback d-block">{formik.errors.role}</div> : null}
                                </div>
                                <hr/>
                                <div className="mx-auto d-flex justify-content-center">
                                    <button className="btn btn-success mx-3" type="submit">Save</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddUser;