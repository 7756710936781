import {createSlice} from '@reduxjs/toolkit'

export const alertSlice = createSlice({
    name: 'alertState',
    initialState: {
        alerts: []
    },
    reducers: {
        addAlert: (state, action) => {
            return {
                ...state,
                alerts: [action.payload, ...(state.alerts)]
            }
        },
        removeAlert: (state, action) => {
            let filtered = state.alerts.filter(a => a.id !== action.payload)
            return {
                ...state,
                alerts: filtered
            }
        },
        removeOldestAlert: (state, action) => {
            let filtered = state.alerts.shift()
            return {
                ...state,
                alerts: filtered
            }
        }
    },
})

export const {addAlert, removeAlert, removeOldestAlert} = alertSlice.actions

export const selectAlerts = (state) => state.rootReducer.alerts

export default alertSlice.reducer