import React, {Component} from 'react'
import {Link} from "react-router-dom";

class ProudIntroduce extends Component {

    render() {
        return (
            <section className="py-4 py-xl-5">
                <div className="container">
                    <div className="text-center p-4 p-lg-5">
                        <p className="fw-bold text-primary mb-2">Proud to introduce</p>
                        <h1 className="fw-bold mb-4">Our new system to manage users' visits</h1>
                        <Link to={'/login'} className="btn btn-primary fs-5 me-2 py-2 px-4">Log In</Link>
                    </div>
                </div>
            </section>
        )
    }
}

export default ProudIntroduce