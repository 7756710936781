import React, {useState} from "react";
import Axios from "axios";
import {setAuthToken} from "./index";
import {Navigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {logIn, selectLoggedIn} from "./redux/loggedInSlice";
import {useFormik} from "formik";
import validateLogin from "./validation/validateUser";

export default function LoginPage() {
    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
        },
        validate: validateLogin,
        onSubmit: values => {
            const response = Axios.post('/auth/sign-in', {
                username: values.username,
                password: values.password
            }, {
                headers: {
                    'Content-Type': 'application/json'
                },
            })

            response.then((response) => {
                if (response.status === 200) {
                    localStorage.setItem('token', response.data.token);
                    setAuthToken(response.data.token);
                    dispatch(logIn(response.data))
                }
            }).catch((error) => {
                if (error.status === 401) {
                    window.alert('Invalid username and/or password');
                } else if (error.status !== 200) {
                    window.alert('Error logging in')
                }
            })
        }
    })

    const dispatch = useDispatch();
    const loggedInSelector = useSelector(selectLoggedIn);

    const playSoundOnLogin = () => {
        let src = 'https://assets.mixkit.co/active_storage/sfx/2841/2841.wav';
        let audio = new Audio(src);
        audio.play();
    }

    return (
        <div className="container">
            {loggedInSelector.loggedIn && <Navigate to="/welcome" replace={true}/>}
            <section className="position-relative py-4 py-xl-5">
                <div className="container">
                    <div className="row mb-5">
                        <div className="col-md-8 col-xl-6 text-center mx-auto">
                            <h2>Log in</h2>
                            <p className="w-lg-50">Please log in to access the application.</p>
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center">
                        <div className="col-md-6 col-xl-4">
                            <div className="card mb-5">
                                <div className="card-body d-flex flex-column align-items-center">
                                    <div className="bs-icon-xl bs-icon-circle bs-icon-primary bs-icon my-4">
                                        <svg className="bi bi-person" xmlns="http://www.w3.org/2000/svg" width="1em"
                                             height="1em" fill="currentColor" viewBox="0 0 16 16">
                                            <path
                                                d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"></path>
                                        </svg>
                                    </div>
                                    <form className="text-center" onSubmit={formik.handleSubmit}>
                                        <div className="mb-3">
                                            <input
                                                className="form-control"
                                                id="username"
                                                name="username"
                                                type="text"
                                                placeholder="Username"
                                                onChange={formik.handleChange}
                                                value={formik.values.username}
                                            />
                                            {formik.errors.username ? <div className="invalid-feedback d-block">{formik.errors.username}</div> : null}
                                        </div>
                                        <div className="mb-3">
                                            <input
                                                className="form-control"
                                                type="password"
                                                name="password"
                                                id="password"
                                                value={formik.values.password}
                                                onChange={formik.handleChange}
                                                placeholder="Password"
                                            />
                                            {formik.errors.password ? <div className="invalid-feedback d-block">{formik.errors.password}</div> : null}
                                        </div>
                                        <div className="mb-3">
                                            <button className="btn btn-primary d-block w-100" type="submit" onClick={playSoundOnLogin}>
                                                Login
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}