import {Link} from "react-router-dom";
import React from "react";

export default function NavBlockNotLoggedIn() {
    return (
        <div className="collapse navbar-collapse" id="nav-menu">
            <ul className="navbar-nFav mx-auto"></ul>
            <Link to={'/login'} className="btn btn-primary">Log In</Link>
        </div>
    );
}