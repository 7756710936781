import React from 'react'
import ProudIntroduce from "./static/ProudIntroduce";
import MamontFeatures from "./static/MamontFeatures";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {selectLoggedIn} from "./redux/loggedInSlice";

export default function NotLoggedInPage() {
    const loggedInSelector = useSelector(selectLoggedIn);
    const navigate = useNavigate();

    return (
        <div>
            <ProudIntroduce />
            <MamontFeatures />
            {loggedInSelector.loggedIn ? navigate("/welcome") : null}
        </div>
    );
}